<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <div class="box-name-page">
        <p class="title">ผู้ใช้งาน</p>
        <div class="is-flex ai-center" v-if="checkPermissionMore()">
          <el-tooltip
            v-if="dataFetchWs != []"
            class="item"
            effect="dark"
            content="มีกำหนดการ Sync ข้อมูลกับ WS เวลา 00.00 น."
            placement="bottom"
            :disabled="dataFetchWs.active == 1 ? true : false"
          >
            <!--  -->

            <el-button
              class="btn-sync min-100"
              @click="submitSyncWs(dataFetchWs.active)"
              :class="dataFetchWs.active == 0 ? 'disabled' : 'enable'"
              :loading="loadingWs"
            >
              <i class="far fa-clock mg-r-7" v-if="dataFetchWs.active == 0"></i>
              <span> Sync </span>
            </el-button>
          </el-tooltip>

          <el-button
            type="primary"
            @click="$router.push('/manage-admin/add-admin/add')"
          >
            เพิ่มผู้ใช้งาน
          </el-button>
        </div>
      </div>
      <el-row :gutter="20">
        <el-form label-position="top" class="set-form">
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ฝ่าย">
              <el-select
                id="selectDepartment"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.department"
                placeholder="เลือกฝ่าย"
                popper-class="custom-select"
                class="w-100"
                @change="selectDepartment"
                :loading="loadingDepartment"
              >
                <template slot="empty" v-if="!loadingDepartment">
                  <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                </template>
                <el-option
                  v-for="item in optionsDepartment"
                  :key="item.id"
                  :label="item.depName"
                  :value="item.id"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.depName }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ส่วน">
              <el-select
                id="selectDivision"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.division"
                placeholder="เลือกส่วน"
                popper-class="custom-select"
                class="w-100"
                @change="selectDivision"
                :disabled="form.department == '' ? true : false"
              >
                <template slot="empty">
                  <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                </template>
                <el-option-group
                  v-for="group in optionsDivision"
                  :key="group.value"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.option"
                    :key="item.id"
                    :label="item.divName"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.divName }}
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ตำแหน่ง">
              <el-select
                id="selectPosition"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.position"
                placeholder="เลือกตำแหน่ง"
                popper-class="custom-select"
                class="w-100"
                @change="selectAllPosition"
                :disabled="form.division == '' ? true : false"
              >
                <template slot="empty">
                  <p class="el-select-dropdown__empty">ไม่มีข้อมูล</p>
                </template>
                <el-option-group
                  v-for="group in optionsPosition"
                  :key="group.value"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.option"
                    :key="item.id"
                    :label="item.posName"
                    :value="item.id"
                  >
                    <div class="is-flex ai-center">
                      <div class="circle-select"></div>
                      {{ item.posName }}
                    </div>
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="สถานะใน ธสน.">
              <el-select
                id="selectEmpStatus"
                v-model="form.empStatus"
                placeholder="เลือกสถานะใน ธสน."
                @change="searchAdminAll"
              >
                <el-option
                  v-for="item in optionsStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="ค้นหา">
              <el-input
                clearable
                id="searchAdmin"
                placeholder="ค้นหาชื่อ/นามสกุล/อีเมล"
                suffix-icon="el-icon-search"
                v-model="form.searchData"
                class="search"
                @input="searchAdminAll"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="สถานะใน CFOE">
              <el-select
                id="selectCfoeStatus"
                v-model="form.cfoeStatus"
                placeholder="เลือกสถานะ"
                @change="searchAdminAll"
              >
                <el-option
                  v-for="item in optionsCfoeStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24" :sm="12" :md="6">
            <el-form-item label="สิทธิ์ผู้ใช้งาน">
              <el-select
                id="selectRole"
                multiple
                filterable
                default-first-option
                clearable
                v-model="form.role"
                placeholder="เลือกสิทธิ์ผู้ใช้งาน"
                popper-class="custom-select"
                class="w-100"
                @change="searchAdminAll"
              >
                <el-option
                  v-for="item in optionsRole"
                  :key="item._id"
                  :label="item.roleName"
                  :value="item._id"
                >
                  <div class="is-flex ai-center">
                    <div class="circle-select"></div>
                    {{ item.roleName }}
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- -->
        </el-form>
      </el-row>
    </div>
    <div class="box-card mg-t-5">
      <p class="text-right color-9c font-18">
        <span>Last Update: {{ timeSync }} น.</span>
      </p>
      <el-row :gutter="20">
        <el-col :span="24">
          <TableAdmin
            :data="dataRes.data"
            :page="`addAdmin`"
            :loading="loading"
          />

          <div class="text-center mg-t-5" v-if="totalNow > limit">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="changePage"
              :current-page.sync="pageNow"
              :page-sizes="[15, 30, 50, 100, 500]"
              :page-size="15"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalNow"
            >
            </el-pagination>
            <!-- <el-pagination
              background
              layout="prev, pager, next"
              :total="totalNow"
              :page-size="limit"
              :current-page="pageNow"
              @current-change="changePage"
            ></el-pagination> -->
          </div>
        </el-col>
      </el-row>
    </div>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import TableAdmin from "@/components/admin/TableAdmin";

import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    TableAdmin,
  },
  data() {
    return {
      optionsCfoeStatus: [
        {
          value: "",
          label: "ทั้งหมด",
        },
        {
          value: 1,
          label: "ใช้งาน",
        },
        {
          value: 2,
          label: "ละเว้น",
        },
      ],
      form: {
        cfoeStatus: "",
        department: [],
        division: [],
        position: [],
        empStatus: "Active",
        searchData: "",
        role: [],
      },
      optionsDepartment: [],
      optionsDivision: [],
      optionsPosition: [],
      optionsStatus: [
        {
          value: "",
          label: "ทั้งหมด",
        },
        {
          value: "Active",
          label: "Active",
        },
        {
          value: "InActive",
          label: "InActive",
        },
      ],
      optionsRole: [],
      totalNow: 10,
      limit: 15,
      pageNow: 1,
      dataRes: [],
      loading: false,
      debounce: null,
      dataFetchWs: [],
      loadingWs: false,
      timeSync: "",
      loadingDepartment: true,
      constSelect2: [],
      constSelect3: [],
      loadingData: true,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      permissionNow: (state) => state.permissionNow,
    }),
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "manage-admin/add-admin");
    this.getDepartment();
    this.getRole();
    this.getAdmin();
    this.getStatusWS();
  },
  methods: {
    getStatusWS() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`ws`)
        .then((res) => {
          if (res.data.success) {
            this.dataFetchWs = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    async submitSyncWs(val) {
      if (val == 1) {
        this.loadingWs = true;
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        let res = await HTTP.post(`ws/click`)
          .then((res) => {
            if (res.data.success) {
              if (res.data.obj.active == 0) {
                this.loadingWs = false;
                this.$message({
                  message:
                    "Sync ข้อมูลสำเร็จ \nกรุณรอการอัพข้อมูลเวลา 00.00 น.",
                  type: "success",
                  duration: 4000,
                });
                this.getStatusWS();
              }
            }
            return res;
          })
          .catch((e) => {
            this.loadingWs = false;
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
            return {
              data: {
                success: false,
                data: e,
              },
            };
          });
        return res;
      } else {
        return {
          data: {
            success: false,
            data: "value 2",
          },
        };
      }
    },
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    getManageId(val) {
      if (val.length > 0) {
        this.multipleSelectionId = val;
      } else {
        this.multipleSelectionId = [];
      }
    },
    getAdmin() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let obj = {
        page: this.pageNow,
        limit: this.limit,
        key: this.form.searchData,
        depId: this.form.department,
        divId: this.form.division,
        posId: this.form.position,
        empStatus: this.form.empStatus,
        cfoeStatus: this.form.cfoeStatus,
        roleId: this.form.role,
      };

      HTTP.post(`admin-emp`, obj)
        .then((res) => {
          if (res.data.success) {
            this.timeSync = res.data.obj.syncLatest;
            this.dataRes = res.data.obj;
            this.totalNow = res.data.obj.totalPage;
            this.loading = false;
            this.loadingData = false;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          this.loading = false;
          this.loadingData = false;
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 422) {
              this.dataRes = [];
              this.totalNow = 0;
            } else if (e.response.data.status == 502) {
              console.log("Bad Gateway");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getRole() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role`)
        .then((res) => {
          if (res.data.success) {
            // this.timeSync = res.data.syncLatest;
            this.optionsRole = res.data.obj;
            this.optionsRole.unshift({
              _id: Number("999"),
              roleName: "ไม่มีสิทธิ์ผู้ใช้งาน",
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    searchAdminAll() {
      this.pageNow = 1;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getAdmin();
      }, 600);
    },

    handleSizeChange(val) {
      this.limit = val;
      this.changePage(1);
    },
    changePage(page) {
      this.pageNow = page;
      this.loading = true;
      setTimeout(() => {
        this.getAdmin();
      }, 100);
    },
    selectDepartment() {
      this.optionsDivision = [
        {
          option: [{ id: 999, divName: "ทั้งหมด" }],
        },
      ];
      this.form.department.forEach((dep) => {
        const setArr = this.optionsDepartment.filter((f) => f.id == dep);
        const forOption = [];
        setArr.forEach((arr) => {
          if (arr.division.length > 0) {
            arr.division.forEach((div) => {
              forOption.push(div);
            });
          }
        });
        let obj = {
          label: setArr[0].depName,
          option: forOption,
        };
        this.optionsDivision.push(obj);
        /// เปลี่ยนแปลง ส่วน
      });
      // if (this.form.division.length > 0) {
      //     let findDiv999 = this.form.division.find((e) => e == 999);
      //     if (typeof findDiv999 != "undefined") {
      //       let groupDiv = [];
      //       this.optionsDivision.forEach((f) => {
      //         f.option.forEach((option) => {
      //           groupDiv.push(option.id);
      //         });
      //       });
      //       this.form.division = groupDiv;
      //     } else {
      //       console.log(this.form.division)
      //       let groupDiv = [];

      //       this.form.division.forEach((div) => {
      //         this.optionsDivision.forEach((f) => {
      //           f.option.forEach((option) => {
      //             if (option.id == div) {
      //               groupDiv.push(option.id);
      //             }
      //           });
      //         });
      //       });
      //       this.form.division = groupDiv;
      //     }
      //   }
      this.selectDivision();
      this.selectAllPosition();
      if (this.form.department == 0) {
        this.form.division = [];
        this.form.position = [];
      }
      this.searchAdminAll();
    },
    selectDivision(val) {
      if (typeof val == "undefined") {
        let findDiv999 = this.form.division.find((e) => e == 999);
        // this.form.position = [];
        if (typeof findDiv999 != "undefined") {
          let groupDiv = [];
          // this.form.department.
          if (this.form.department.length > 0) {
            this.optionsDivision.forEach((f) => {
              f.option.forEach((option) => {
                groupDiv.push(option.id);
              });
            });
          }
          this.form.division = groupDiv;
          this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
        } else {
          if (this.form.division.length > 0) {
            let groupDiv = [];
            this.form.division.forEach((row) => {
              this.optionsDivision.forEach((f) => {
                f.option.forEach((option) => {
                  if (option.id == row) {
                    groupDiv.push(option.id);
                  }
                });
              });
            });
            this.form.division = groupDiv;
          }
        }
      } else {
        if (val.length == 1) {
          if (val[0] === 999 && val.length < this.constSelect2.length) {
            // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
            this.form.division.splice(0, 1);
            this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
          }
        }
        this.selectAllDiv(val);
      }
      this.optionsPosition = [
        {
          option: [{ id: 999, posName: "ทั้งหมด" }],
        },
      ];
      let setArr = [];
      this.form.division.forEach((e) => {
        this.optionsDivision.forEach((f) => {
          f.option.forEach((option) => {
            if (option.id == e && option.id != 999) {
              setArr.push(option);
            }
          });
        });
      });
      setArr.forEach((arr) => {
        let forOption = [];
        if (arr.position.length > 0) {
          arr.position.forEach((div) => {
            forOption.push(div);
          });
        }
        let obj = {
          label: arr.divName,
          option: forOption,
        };
        this.optionsPosition.push(obj);
      });
      if (this.form.division.length == 0) {
        this.form.position = [];
      }
      this.selectAllPosition();
      this.searchAdminAll();
    },
    selectAllDiv(val) {
      let countDiv = [];
      if (typeof val != "undefined") {
        if (val[val.length - 1] == 999) {
          // เลือกทั้งหมด
          this.form.division = [];
          let groupDiv = [];
          this.optionsDivision.forEach((f) => {
            f.option.forEach((option) => {
              if (option != 999) {
                groupDiv.push(option.id);
              }
            });
          });
          this.form.division = groupDiv;
          this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
        } else {
          this.optionsDivision.forEach((row) => {
            row.option.forEach((option) => {
              countDiv.push(option);
            });
          });
          if (val[0] === 999 && val.length < countDiv.length) {
            // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
            this.form.division.splice(0, 1);
            this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
          } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
            // เอาออกทั้งหมด
            this.form.division = [];
            this.constSelect2 = JSON.parse(JSON.stringify(this.form.division));
          } else {
            // Tik ลูกทั้งหมดแล้วให้ เลือกทั้งหมด
            let fn = this.constSelect2.findIndex((a) => a === 999);
            if (val.length == countDiv.length - 1 && fn === -1) {
              this.form.division = [];
              this.optionsDivision.forEach((f) => {
                f.option.forEach((option) => {
                  this.form.division.push(option.id);
                });
              });
              this.constSelect2 = JSON.parse(
                JSON.stringify(this.form.division)
              );
            }
          }
        }
      }
    },
    selectAllPosition(val) {
      let countDiv = [];
      if (this.form.position.length > 0) {
        if (typeof val != "undefined") {
          if (val.length == 1) {
            if (val[0] === 999 && val.length < this.constSelect3.length) {
              // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
              this.form.position.splice(0, 1);
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.form.position)
              );
            }
          }
          if (val[val.length - 1] == 999) {
            // เลือกทั้งหมด
            this.form.position = [];
            this.optionsPosition.forEach((f) => {
              f.option.forEach((option) => {
                if (option != 999) {
                  this.form.position.push(option.id);
                }
              });
            });
            this.constSelect3 = JSON.parse(JSON.stringify(this.form.position));
          } else {
            this.optionsPosition.forEach((row) => {
              row.option.forEach((option) => {
                countDiv.push(option);
              });
            });
            if (val[0] === 999 && val.length < countDiv.length) {
              // มีเลือกทั้งหมดอยู่แล้ว Tik ลูกออก และเลือกทั้งหมดจะถูกเอาออก
              this.form.position.splice(0, 1);
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.form.position)
              );
            } else if (this.constSelect3[0] === 999 && val[0] !== 999) {
              // เอาออกทั้งหมด
              this.form.position = [];
              this.constSelect3 = JSON.parse(
                JSON.stringify(this.form.position)
              );
            } else {
              // Tik ลูกทั้งหมดแล้วให้ เลือกทั้งหมด
              let fn = this.constSelect3.findIndex((a) => a === 999);
              if (val.length == countDiv.length - 1 && fn === -1) {
                this.form.position = [];
                this.optionsPosition.forEach((f) => {
                  f.option.forEach((option) => {
                    this.form.position.push(option.id);
                  });
                });
                this.constSelect3 = JSON.parse(
                  JSON.stringify(this.form.position)
                );
              }
            }
          }
        } else {
          let findPosition999 = this.form.position.find((e) => e == 999);
          if (typeof findPosition999 != "undefined") {
            let groupDiv = [];
            this.optionsPosition.forEach((f) => {
              f.option.forEach((option) => {
                if (option != 999) {
                  groupDiv.push(option.id);
                }
              });
            });
            this.form.position = groupDiv;
          } else {
            if (this.form.position.length > 0) {
              let groupDiv = [];

              this.form.division.forEach((row) => {
                this.form.position.forEach((pos) => {
                  this.optionsPosition.forEach((f) => {
                    f.option.forEach((option) => {
                      if (
                        option.divId == row &&
                        option.id != 999 &&
                        option.id == pos
                      ) {
                        groupDiv.push(option.id);
                      }
                    });
                  });
                });
              });
              this.form.position = groupDiv;
            }
          }
        }
      }
      this.searchAdminAll();
    },
    getDepartment() {
      HTTP.get(`department`)
        .then((res) => {
          if (res.data.success) {
            this.loadingDepartment = false;
            this.optionsDepartment = res.data.obj;
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined") {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
          }
        });
    },
  },
};
</script>